* {
  box-sizing: border-box;
}


.home {
  background-color: #ffb947;
  line-height: 100px;
  color: #000000;
  font-family: 'Inter';
  font-size: 1.5rem;
  font-weight: 700;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

a {
  color: #000000;
  text-decoration: none;
}

.header-container {
  display: flex;
  align-items: start; /* Aligns children to the top */
  justify-content: space-between; /* Separates logo and menu */
  padding: 20px 40px;
}

.header-container .menu {
  display: flex;
  justify-content: center;
  flex: 1;
}

.menu {
  flex: 1;
  justify-content: center;
  align-self: start;
}

.menu-links a {
  margin: 20px;
  color: #000;
  font-family: 'Inter';
  font-size: 1.5rem;
  text-decoration: none;
  transition: filter 0.25s ease; 
  filter: contrast(100%);
}

.menu-links a:hover {
  filter: contrast(25%);
}

.logo img {
  width: 150px;
  height: auto;
  flex: 0 0 auto;
}

span.highlighted {
  background: linear-gradient(to bottom, #fdffeb, #ff7700);
  border-radius: 10px;
  padding: 0 10px;
}

span.solana {
    background-image: linear-gradient(to right, #9945FF, #14F195);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }


.hero-section-container {
  font-weight: 500;
  line-height: 50px;
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  align-self: flex-end;
}

@media only screen and (max-width: 768px) {
  .hero-section-container {
    flex-direction: column;
    line-height: normal;
  }

  .hero-info-wrapper .hero-info-text h1 {
    font-size: 24px;
  }

  .menu-links a {
    margin: 10px;
  }
}

.hero-section-container .hero-info-wrapper {
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-basis: 47%;
}

.hero-section-container .hero-info-text h1 {
  font-weight: 900;
  font-size: 5rem;
  line-height: 75px;
  font-family: 'Inter';
}

p {
  font-size: 1.5rem;
}

.hero-image-container .hero-image {
  width: 712px;
  height: auto;
  object-fit:cover;
  margin-top: 11px;
  margin-bottom: 9px;
  margin-right: 100px;
}

@media only screen and (max-width: 768px) {
  .hero-image-container .hero-image {
    width: 80%;
    height: auto;
    align-items: center;
    margin-top: -60px;
    margin-left: 20px;
   
  }
}

.gradient-border {
  background: linear-gradient(#ff7700 0 0) padding-box, linear-gradient(to right, transparent,#fff) border-box;
  border-radius: 10px;
  background-origin: border-box;
  background-clip: padding-box, border-box;
  border: double 1px transparent; 
}

.stats-container {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  width: 75%;
  background-color: #ff7700;
  border-radius: 10px;
  font-weight: 500;
  line-height: 43px;
  margin-top: 20px;
}

@media only screen and (max-width: 768px) {
  .stats-container {
    flex-direction: column;
  }
}

.stats-container .metric-container {
  display: flex;
  padding: 18px;
  color: #fff;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.stats-container .metric-container .metric-title {
  color: #ffb947;
  font-weight: bold;
  font-size: 18px;
}

.stats-container .metric-container .metric-value {
  align-self: flex-end;
  font-size: 36px;
  font-weight: medium;
}

.footer-container {
  width: 100%;
  display: flex;
  color: #000;
}

.footer-container .footer-copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 10%;
}

@media only screen and (max-width: 768px) {
  .footer-container .footer-copyright {
    flex-direction: column;
  }
}

span.glostars {
  background-image: linear-gradient(to bottom, #000000, #86152a,#fff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

body, html {
  cursor: url('./catmouse1.png'), default;
}

a, button {
  cursor: url('./catmouse2.png'), pointer;
}